
































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';

@Component
export default class DeleteDialog extends Vue {

  @VModel({ type: Boolean, default: false })
  private show!: boolean;

  @Prop({ default: 'Löschen' })
  private readonly title!: string;

  @Prop({ required: true })
  private readonly message!: string;

  @Emit()
  private confirm() {
    // emits event
  }

  @Emit()
  private cancel() {
    // emits event
  }
}
