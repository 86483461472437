






























































































































import { Component, Vue } from 'vue-property-decorator';
import Services from '@/assets/services/Services';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';
import { ProjectOverviewRestDto } from '@/apis/projectapi';
import Header from '@/assets/headers';
import Sort from '@/assets/types/sorting';
import { Filter } from '@/assets/types/types';
import SearchBar from '@/components/partials/SearchBar.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import ProjectGrid from '@/components/projects/ProjectGrid.vue';
import ButtonType from '@/assets/buttonTypes';
import { InnovamoUserConsent } from '@/apis/userapi';
import DeleteDialog from '@/components/DeleteDialog.vue';
import UserConsentTableCard from '@/components/table/UserConsentTableCard.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import DateService from '@/assets/services/DateService';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import Page from '@/components/partials/Page.vue';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';

@Component({
  components: {
    RoundedButtonOutlined,
    TextHeader,
    SearchBar,
    ProjectGrid,
    DeleteDialog,
    UserConsentTableCard,
    LoadingAnimation,
    CustomSelect,
    Page,
    UnexpectedErrorMessage,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class MyApps extends Vue {
  private ButtonType = ButtonType;
  private Header = Header;
  private SortBy = Sort;
  private dateTimeFormatter = DateService.dateTimeFormatter;

  private pageNumber = 0;
  private totalPages = 0;
  private filterValue: Filter = undefined;
  private searchTerm: string = '';

  private userId: string = '';
  private projects: ProjectOverviewRestDto[] = [];

  private consents: InnovamoUserConsent[] = [];
  private clientName: string = '';
  private showDeleteDialog = false;
  private deleteDialogMessage = '';
  private loading: boolean = true;
  private showUnexpectedErrorMessageMyProjects: boolean = false;
  private showUnexpectedErrorMessageUserConsents: boolean = false;
  private showUnexpectedErrorMessageAppsWithInnovamoLogin: boolean = false;

  private projectWithInnovamoLogin: ProjectOverviewRestDto[] = [];
  private sortValue: Option = {
    name: 'Erscheinungsdatum (ab)',
    value: Sort.DATE_DESCENDING,
  };
  private sortOptions: Option[] = [
    {
      name: 'Erscheinungsdatum (ab)',
      value: Sort.DATE_DESCENDING,
    },
    {
      name: 'Erscheinungsdatum (auf)',
      value: Sort.DATE_ASCENDING,
    },
    {
      name: 'Name (ab)',
      value: Sort.NAME_DESCENDING,
    },
    {
      name: 'Name (auf)',
      value: Sort.NAME_ASCENDING,
    },
  ];

  private async mounted() {
    this.userId = this.$store.state.userId;

    try {
      this.projects = await this.searchProjects(
        this.userId,
        this.searchTerm,
        this.sortValue.value,
      );
    } catch (e) {
      this.showUnexpectedErrorMessageMyProjects = true;
    }

    try {
      this.consents = await this.getInnovamoUserConsents();
    } catch (e) {
      this.showUnexpectedErrorMessageUserConsents = true;
    }

    try {
      const projectWithInnovamoLoginResponse = await Services.projects.getProjectsWithInnovamoLogin();
      this.projectWithInnovamoLogin = projectWithInnovamoLoginResponse.data;
    } catch (e) {
      this.showUnexpectedErrorMessageAppsWithInnovamoLogin = true;
    }

    this.loading = false;
  }

  private async getInnovamoUserConsents(): Promise<InnovamoUserConsent[]> {
    const innovamoUserConsentsResponse = await Services.users.getInnovamoUserConsents(this.$store.state.userId);
    return innovamoUserConsentsResponse.data;
  }

  private getGrantedClientScopesStrings(grantedClientScopes: string[]): string[] {
    const grantedClienScopesStrings: string[] = [];
    grantedClientScopes.forEach((grantedClientScope) => {
      if (grantedClientScope === 'profile') {
        grantedClienScopesStrings.push('Vor- und Nachname');
      } else if (grantedClientScope === 'email') {
        grantedClienScopesStrings.push('E-Mail-Adresse');
      } else if (grantedClientScope === 'roles') {
        grantedClienScopesStrings.push('Nutzerrolle');
      }
    });
    return grantedClienScopesStrings;
  }

  private async revokeConsent(clientName: string) {
    this.showDeleteDialog = true;
    this.clientName = clientName;
    this.deleteDialogMessage = `Möchten Sie den Zugriff auf Ihre Daten von der App "${this.clientName}" wirklich löschen? Nach dem Löschen müssen Sie den Zugriff auf Ihre Daten erneut zustimmen.`;
  }

  private async revokeUserConsent() {
    await Services.users.revokeInnovamoUserConsent(this.$store.state.userId, this.clientName);
    this.consents = await this.getInnovamoUserConsents();
    this.showDeleteDialog = false;
    this.clientName = '';
  }

  private revokeUserConsentCanceled() {
    this.showDeleteDialog = false;
    this.clientName = '';
  }

  private sortValueChanged() {
    this.searchButtonClicked(this.searchTerm);
  }

  private async searchButtonClicked(searchTerm: string) {
    this.pageNumber = 0;
    this.searchTerm = searchTerm;
    this.projects = await this.searchProjects(
      this.userId,
      searchTerm,
      this.sortValue.value,
    );
  }

  private async searchProjects(
    userId: string,
    searchTerm: string,
    sortBy: Sort,
  ): Promise<ProjectOverviewRestDto[]> {
    this.loading = true;
    try {
      const response = await Services.projects.getUserLikedProjects(
        userId,
        this.pageNumber,
        searchTerm,
        sortBy.value,
        sortBy.order,
      );
      this.pageNumber = response.data.currentPage as number;
      this.totalPages = response.data.totalPages as number;
      return response.data.projects as ProjectOverviewRestDto[];
    } catch (e) {
      return [];
    } finally {
      this.loading = false;
    }
  }

  private get showMoreButtonEnabled(): boolean {
    return this.pageNumber < this.totalPages - 1;
  }

  private async showMore() {
    this.pageNumber += 1;
    const additionalProjects = await this.searchProjects(
      this.userId,
      this.searchTerm,
      this.sortValue.value,
    );
    this.projects.push(...additionalProjects);
  }
}
