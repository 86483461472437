















import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import RoundedButtonOutLined from '@/components/partials/RoundedButtonOutlined.vue';
import BaseTableCard from '@/components/table/BaseTableCard.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import TableCardEntry from '@/components/table/TableCardEntry.vue';
import Header from '@/assets/headers';

@Component({
  components: {
    RoundedButtonOutLined,
    BaseTableCard,
    TextHeader,
    TableCardEntry,
  },
})
export default class UserConsentTableCard extends Vue {
    private Header = Header;

    @Prop({ required: true })
    private name!: string;

    @Prop({ required: true })
    private grantedClientScopes!: string[];

    @Prop({ required: true })
    private created!: string;

    @Prop({ required: true })
    private updated!: string;

    @Emit()
    private edit() {
      // emits event
    }

    @Emit()
    private remove() {
      // emits event
    }

}
